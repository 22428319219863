<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-rocket"
      title="Users"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
              <template #item.last_logged_in_at="{value}">
                {{ formatIsoDate(value, true) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <router-link
                  style="text-decoration: none; color: inherit;"
                  :to="{ name: 'Edit User', params: { user_id: item.id } }"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >
                    mdi-pencil
                  </v-icon>
                </router-link>
                <v-icon
                  small
                  @click="deleteUser(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  @click="showInviteDialog()"
                >
                  Invite User
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      icon="mdi-account-plus"
      title="Open Invites"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="userInviteHeaders"
              :items="userInvites"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  @click="deleteInvite(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template #item.created_at="{value}">
                {{ formatIsoDate(value, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showInviteUserDialog"
      scrollable
      persistent
      max-width="600"
      @keydown.esc="showInviteUserDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Invite User
        </v-card-title>
        <template v-if="showAlert">
          <v-row>
            <v-col>
              <v-alert
                prominent
                type="error"
              >
                That email address is already in use. Please choose another one.
              </v-alert>
            </v-col>
          </v-row>
        </template>
        <v-row dense>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
            >
              <div class="pt-9">
                <v-text-field
                  ref="inviteEmail"
                  v-model="inviteEmail"
                  label="What is the user's email address?"
                  outlined
                  rows="2"
                  required
                  :rules="[v => !!v || 'Email address is required']"
                />
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!valid"
            @click="inviteUser()"
          >
            Invite
          </v-btn>
          <v-btn
            @click="showInviteUserDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import UserService from '@/services/UserService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'ChatovateManagerUsers',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        showErrorDialog: false,
        error: '',
        loadingMessage: 'Loading',
        showInviteUserDialog: false,
        search: '',
        loading: false,
        expanded: [],
        singleExpand: false,
        valid: true,
        items: [],
        userInvites: [],
        inviteEmail: null,
        showAlert: false,
        headers: [
          { text: 'First Name', value: 'first_name' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Last Logged In', value: 'last_logged_in_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        userInviteHeaders: [
          { text: 'Email', value: 'email' },
          { text: 'Inviter', value: 'inviter', align: 'center' },
          { text: 'Created', value: 'created_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadUsers()
        this.getOpenInvites()
      },
    },
    created () {
      this.loadUsers()
      this.getOpenInvites()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadUsers: function () {
        this.loading = true
        UserService.getUsers(null)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getOpenInvites: function () {
        this.loading = true
        UserService.getOpenInvites()
          .then(response => {
            this.userInvites = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      deleteInvite (item) {
        const index = this.userInvites.indexOf(item)
        if (confirm('Are you sure you want to delete this invite?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          UserService.deleteUserInvite(item.uuid)
            .then(response => {
              this.loading = false
              this.userInvites = this.userInvites.filter(function (el) { return el.uuid !== item.uuid })
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      deleteUser (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this user?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          UserService.deleteUser(item.id)
            .then(response => {
              this.loading = false
              this.items = this.items.filter(function (el) { return el.id !== item.id })
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      showInviteDialog: function () {
        this.inviteEmail = null
        this.showInviteUserDialog = true
        this.$nextTick(() => {
          this.$refs.inviteEmail.$refs.input.focus()
        })
      },
      inviteUser: function () {
        this.loading = true
        this.showAlert = false
        UserService.createUserInvite(this.inviteEmail)
          .then(response => {
            this.showInviteUserDialog = false
            this.loading = false
            this.getOpenInvites()
          })
          .catch(error => {
            if (error.response.status === 418) {
              this.showAlert = true
              this.loading = false
              this.valid = true
            } else {
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
              this.loading = false
              this.valid = true
            }
          })
      },
    },
  }
</script>
